import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../component/navbar/Navbar";
import userinfo_api from "../../api/UserInfo";
import invoiceinfo_api from "../../api/InvoiceInfo";
import { Label } from "../../component/ui/label";
import { Input } from "../../component/ui/input";
import { cn } from "../../lib/utils";
import backgroundImage from "../../assetes/img/9D6kyBfmgiv4HD4HjZDrVWpBhI.jpg";

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
function InvoicePayment() {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const { id } = useParams();
  const [pay_el, setPayEl] = useState(<></>);

  const invoice_data = async () => {
    const inv_data = await invoiceinfo_api(id);
    if (inv_data[1] === 200) {
      const json_data = inv_data[0].data;
      const json_metadata = inv_data[0].metadata;
      setPayEl(
        <div dir="rtl" className="dark">
          <div className="flex flex-col gap-[5px]">
            <span className="flex flex-row-reverse gap-[5px]">
              <span>{json_data.full_value}</span>{" "}
              <span>{json_data.currency}</span> <span>مبلغ کل:</span>
            </span>
            <span className="flex flex-row-reverse gap-[5px]">
              <span>{json_data.discount}</span>{" "}
              <span>{json_data.currency}</span> <span>تخفیف:</span>
            </span>
            <span className="flex flex-row-reverse gap-[5px]">
              <span>{json_data.tax}</span>
              <span>{json_data.currency}</span> <span>مالیات:</span>
            </span>

            <span className="flex flex-row-reverse gap-[5px]">
              <span
                className="flex flex-row-reverse gap-[15px]"
                style={{
                  direction: "rtl",
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "0% 10%",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  textFillColor: "transparent",
                  color: "rgb(255, 255, 255)",
                  fontSize: "3em",
                  fontFamily: "dana",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <span>
                  {json_data.full_value > json_data.discount
                    ? json_data.full_value - json_data.discount
                    : 0}
                </span>{" "}
                <span>{json_data.currency}</span>
              </span>
              <span>مبلغ قابل پرداخت:</span>
            </span>
          </div>
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&margin=10&data=${json_metadata.crypto_address}`}
            alt=""
          />
          <span>{json_metadata.crypto_address}</span>
          <div className="flex gap-[10px]">
            <span>{json_metadata.crypto_network}</span>
            <span>{json_metadata.crypto_symbol}</span>
          </div>
          <LabelInputContainer className="mb-4">
            <Label htmlFor="tel_id">مقدار Hash پرداخت</Label>
            <Input id="tel_id" placeholder="مقدار Hash یا TXID" type="tel_id" />
          </LabelInputContainer>{" "}
          <button
            className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            type="submit"
          >
            بررسی پرداخت
            <BottomGradient />
          </button>
        </div>
      );
    } else if (inv_data[1] === 401) {
      window.location.href = `/Login?call_back=/invoice/payment/${id}`;
    }
  };

  const first_data = async () => {
    const user_data = await userinfo_api();
    if (user_data[1] === 200) {
      setName(user_data[0].data.name);
      setPhone(user_data[0].data.phone);
    }
  };
  useEffect(() => {
    first_data();
    invoice_data();
  }, []);
  return (
    <div>
      <ToastContainer rtl={true} position="bottom-right" />
      <Navbar name={name} phone={phone} />
      {pay_el}
    </div>
  );
}
export default InvoicePayment;
