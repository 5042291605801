import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const token = localStorage.getItem("token");
    if (token === null) {
      return [null, 401];
    } else {
      const response = await axios.post(link, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = response.data;
      return [result, response.status];
    }
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const buyPlans_api = async (plan_id, plan_time) => {
  const formData = {
    plan_id: plan_id,
    plan_time: plan_time,
    currency: "USDT",
  };
  const data = await fetchDataFromLink(`${base_url}/plans/buy`, formData);
  return data;
};

export default buyPlans_api;
