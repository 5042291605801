import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const response = await axios.get(link);
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const plans_api = async (phone) => {
  const formData = {};
  const data = await fetchDataFromLink(`${base_url}/plans`, formData);
  return data;
};

export default plans_api;
