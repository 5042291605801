import style from "./Counseling.module.css";
import img from "../../assetes/img/login.png";
import { LogoutCurve } from "iconsax-react";
import { Link } from "react-router-dom";
function Counseling() {
  return (
    <div className={style.content}>
      <div className={style.imgbox}>
        <img src={img} />
      </div>
      <div className={style.textbar}>
        <div className={style.textbarTap}>
          <div className={style.bg}>
            <div className={style.TapR}>
              <h4>چه سوالی دارید؟</h4>
              <h6>لطفا یکی از گزینه های زیر را انتخاب کنید</h6>
            </div>
            <div className={style.TapL}>
              <h6>شما با شماره زیر وارد شدید</h6>
              <div className={style.TapLnumber}>
                <h2>09105623383</h2>
                <LogoutCurve className={style.LogoutCurve} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.red}></div>
        <div className={style.box}>
          <div className={style.boxbar}>
            <div className={style.input}>
              <input type="radio" />
              <h1>کسب درامد و مشاوره در فرصت های ارز دیجیتال</h1>
            </div>
            <ul>
              <li>نحوه افتتاح حساب در صرافی ایرانی و خارجی</li>
              <li>نحوه نصب و راه اندازی کیف پول ها</li>
              <li>نحوه خرید و فروش</li>
              <li>اموزش بستر های ترید و معامله </li>
              <li> اشنایی با شبکه بلاکچین های بازار</li>
            </ul>
          </div>
          <div className={style.boxbar}>
            <div className={style.input}>
              <input type="radio" />
              <h1> مشاوره در حوزه سرمایه گذاری</h1>
            </div>
            <ul>
              <li> اموزش حوزه متاورس و روش های درامد رایی</li>
              <li>نحوه خرید زمین در متاورس و اشنایی با پلتفرم ها</li>
              <li>اموزش وراه های درامد زایی از حوزه NFT</li>
              <li>
                {" "}
                ساخت و ضرب NFT و گام به گام کسب درامد و اشنایی با پلتفرم ها
              </li>
            </ul>
          </div>
          <div className={style.boxbar}>
            <div className={style.input}>
              <input type="radio" />
              <h1>بازیابی و ریکاوری</h1>
            </div>
            <ul>
              <li>بازیابی کیف پول</li>
              <li>بررسی مشکلات نقل و انتقال مالی رد پلتفرم</li>
              <li>بررسی مشکلات نقل و انتقال مالی رد پلتفرم</li>
              <li>بررسی مشکلات نقل و انتقال مالی رد پلتفرم</li>
            </ul>
          </div>
          <div className={style.boxbar}>
            <div className={style.input}>
              <input type="radio" />
              <h1> مشاوره در حوزه دیفای</h1>
            </div>
            <ul>
              <li>آموزش کار با صرافی های غیر متمرکز</li>
              <li>آموزش کار با پروتکل های وام دهی</li>
              <li>آموزش کار با پروتکل های وام دهی</li>
            </ul>
          </div>
        </div>
        <div className={style.boxbut}>
          <textarea
            className={style.comment}
            name="w3review"
            rows="3"
            cols="60"
          >
            لطفا در مورد سوال خود بیشتر توضیح بدهید
          </textarea>
          <div className={style.button}>
          <Link to="/typeConsultation">
            <button>بعدی</button>
          </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Counseling;
