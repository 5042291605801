import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const response = await axios.post(link, data);
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const checkCode_api = async (phone, code) => {
  const formData = {
    phone: phone,
    code: code,
  };
  const data = await fetchDataFromLink(`${base_url}/user/checkCode`, formData);
  return data;
};

export default checkCode_api;
