import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const token = localStorage.getItem("token");
    if (token === null) {
      return [null, 401];
    } else {
      const response = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = response.data;
      return [result, response.status];
    }
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const invoiceinfo_api = async (id) => {
  const formData = {};
  const data = await fetchDataFromLink(
    `${base_url}/invoice/info/${id}`,
    formData
  );
  return data;
};

export default invoiceinfo_api;
