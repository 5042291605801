import style from "./Date.module.css";
import img from "../../assetes/img/login.png";
import { Link } from "react-router-dom";
import { LogoutCurve } from "iconsax-react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import React, { useState } from "react";

function Date() {
  const [selectedDay, setSelectedDay] = useState(null);

  return (
    <div className={style.content}>
      <div className={style.imgbox}>
        <img src={img} />
      </div>
      <div className={style.textbar}>
        <div className={style.textbarTap}>
          <div className={style.bg}>
            <div className={style.TapR}>
              <h4>انتخاب نوع مشاوره</h4>
              <h6>لطفا یکی از گزینه های زیر را انتخاب کنید</h6>
            </div>
            <div className={style.TapL}>
              <h6>شما با شماره زیر وارد شدید</h6>
              <div className={style.TapLnumber}>
                <h2>09105623383</h2>
                <LogoutCurve className={style.LogoutCurve} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.red}></div>

        <div className={style.mainn}>
          <div className={style.right}>
            <h1 className={style.h1}>لطفا تاریخ مد نظر خود را انتخاب کنید</h1>
            <h6>
              پس از انتخاب تاریخ مورد نظر و بررسی کارشناسان، با شما بابت هماهنگی
              زمان دقیق مشاوره تماس گرفته خواهد شد، و در صورت پاسخ ندادن به تماس
              ممکن است تایم مشاوره شما لغو شده و مبلغ اودت نخواهد داده شد.
            </h6>
            <h4 className={style.margin}>تاریخ انتخاب شده:</h4>
            <h5 className={style.text}>سه شنبه , 9 مرداد 1403</h5>
          </div>
          <div className={style.left}>
            {" "}
            <Calendar
              value={selectedDay}
              onChange={setSelectedDay}
              shouldHighlightWeekends
              colorPrimary="#7E42FF"
              colorPrimaryLight="#7E42FF"
              locale="fa"
            />
          </div>
        </div>

        <div className={style.button}>
          <Link to="">
            <button style={{ fontWeight: "bold" }}>ثبت</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Date;
