import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import courseinfo_api from "../../api/CourseInfo";
import { ToastContainer, toast } from "react-toastify";
import { File, Folder, Tree } from "../../component/magicui/file-tree";
import Navbar from "../../component/navbar/Navbar";
import userinfo_api from "../../api/UserInfo";
import { Back } from "iconsax-react";
import "./course.css";
function Course() {
  const { id } = useParams();
  const [treeValue, setTreeValue] = useState(<></>);
  const [content, setContent] = useState(<></>);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);

  let hasRun = false;
  const first_data = async () => {
    const user_data = await userinfo_api();
    if (user_data[1] === 200) {
      setName(user_data[0].data.name);
      setPhone(user_data[0].data.phone);
    }
  };
  const ShowList = () => {
    document.getElementById("trees").classList.add("show-flex");
  };
  const HideList = () => {
    document.getElementById("trees").classList.remove("show-flex");
  };
  const ShowContent = (data) => {
    setContent(
      <div
        style={{ margin: "auto" }}
        className="h-full w-[90%] flex flex-col items-center gap-5 justify-start my-[50px]"
      >
        <span
          dir="rtl"
          className="text-white bold"
          style={{
            direction: "rtl",
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {data.name}
        </span>

        <video
          className="w-[70%] max-w-[500px] rounded-[20px]"
          controls
          src={data.link}
        ></video>
        <p dir="rtl" className="text-[#A9A9A9] w-[70%] text-justify">
          {data.description}
        </p>
        <br />
        <br />
      </div>
    );
    HideList();
  };

  const course_data = async () => {
    if (!hasRun) {
      hasRun = true;
      const data = await courseinfo_api(id);
      if (data[1] === 200) {
        const f_data = data[0];
        setTreeValue(
          <div>
            <Back
              onClick={HideList}
              className="block md:hidden cursor-pointer"
              color="white"
            />
            <Tree
              className="w-[260px] p-2 overflow-hidden rounded-md bg-background"
              initialSelectedId="2"
              initialExpandedItems={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
              ]}
            >
              <Folder dir="rtl" element="دوره رایگان" value="1">
                {f_data.data.videos.map((video, index) => (
                  <File value={index + 2} key={index}>
                    <p onClick={() => ShowContent(video)} dir="rtl">
                      {video.name}
                    </p>
                  </File>
                ))}
              </Folder>
            </Tree>
          </div>
        );
        ShowContent(f_data.data.videos[0]);
      } else if (data[1] === 700) {
        toast.error("به دوره دسترسی ندارید");
        if (id === "66ddbf56716e60ca7ec1eca2") {
          window.location.href = "/free_course";
        } else {
          window.location.href = "/";
        }
      } else if (data[1] === 403) {
        toast.error("به دوره دسترسی ندارید");

        window.location.href = "/";
      } else if (data[1] === 401) {
        window.location.href =
          "/Login?call_back=/courses/66ddbf56716e60ca7ec1eca2";
      }
    }
  };
  useEffect(() => {
    course_data();
    first_data();
  }, []);
  return (
    <div>
      <ToastContainer rtl={true} position="bottom-right" />
      <Navbar name={name} phone={phone} />
      <div className="flex-col items-center md:items-start flex md:flex-row-reverse w-[100%] h-[100%]">
        <div
          id="trees"
          className="w-[100%] h-[100vh] hidden m-auto md:flex md:w-[400px] justify-center flex-row pt-5 md:m-0 md:border-l-2 md:border-l-[#292929]"
        >
          {treeValue}
        </div>
        <button
          onClick={ShowList}
          className="block  mt-5 md:hidden w-[63%] h-[40px] bg-[#7E42FF] text-[white] rounded-lg"
        >
          نمایش لیست دوره
        </button>
        <div className={`w-full  mt-5 md:minm-400 h-full`}>{content}</div>
      </div>
    </div>
  );
}

export default Course;
