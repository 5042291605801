import style from "./TypeConsultation.module.css";
import img from "../../assetes/img/login.png";
import online from "../../assetes/img/2.png";
import inPerson from "../../assetes/img/1.png";
import { Link } from "react-router-dom";
import { LogoutCurve } from "iconsax-react";

function typeConsultation() {
  return (
    <div className={style.content}>
      <div className={style.imgbox}>
        <img src={img} />
      </div>
      <div className={style.textbar}>
        <div className={style.textbarTap}>
          <div className={style.bg}>
            <div className={style.TapR}>
              <h4>انتخاب نوع مشاوره</h4>
              <h6>لطفا یکی از گزینه های زیر را انتخاب کنید</h6>
            </div>
            <div className={style.TapL}>
              <h6>شما با شماره زیر وارد شدید</h6>
              <div className={style.TapLnumber}>
                <h2>09105623383</h2>
                <LogoutCurve className={style.LogoutCurve} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.red}></div>

        <h1>یکی از روش های مشاوره زیر را انتخاب کنید</h1>
        <div className={style.main}>
        <div>
            <img className={style.mainL} src={inPerson} />
          </div>
          <div>
            <img className={style.mainR} src={online} />
          </div>
          
        </div>
        <div className={style.button}>
          <Link to="/Date">
            <button>بعدی</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default typeConsultation;
