import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const token = localStorage.getItem("token");
    if (token === null) {
      return [null, 401];
    } else {
      const response = await axios.post(link, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = response.data;
      return [result, response.status];
    }
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const FreeCourseRegistration_api = async (name = null) => {
  const formData = {
    name: name,
  };
  const finnalData = name !== null ? formData : {};
  const data = await fetchDataFromLink(
    `${base_url}/course/free_course_registration`,
    finnalData
  );
  return data;
};

export default FreeCourseRegistration_api;
