import style from "./Login.module.css";
import img from "../../assetes/img/login.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import login_api from "../../api/Login";
import checkCode_api from "../../api/checkCode";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Login() {
  const [numberInputValue, setNumberInputValue] = useState("");
  const [codeInputValue, setCodeInputValue] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const call_back = queryParams.get("call_back");
  const handleNumberChange = (event) => {
    setNumberInputValue(event.target.value);
  };
  const handleCodeChange = (event) => {
    setCodeInputValue(event.target.value);
  };
  const handleLoginButton = async () => {
    const continer = document.getElementById("continer");
    const continer2 = document.getElementById("continer2");
    const button = document.getElementById("loginButton");
    button.disabled = true;
    button.style.backgroundColor = "#7e42ff48";
    const api_data = await login_api(numberInputValue);
    if (api_data[1] === 200) {
      button.style.backgroundColor = "#7E42FF";
      continer.style.display = "none";
      continer2.style.display = "flex";
      toast.success("کد ارسال شد");
      button.disabled = false;
      button.style.backgroundColor = "#7e42ff";
    } else if (api_data[1] === 429) {
      toast.error("2 دقیقه دیگر تلاش کنید");
      button.disabled = false;
      button.style.backgroundColor = "#7e42ff";
    } else {
      toast.error("مشکلی پیش آمده");
      button.disabled = false;
      button.style.backgroundColor = "#7e42ff";
    }
  };
  const handleCodeButton = async () => {
    const button = document.getElementById("codeButton");
    button.disabled = true;
    button.style.backgroundColor = "#7e42ff48";
    const api_data = await checkCode_api(numberInputValue, codeInputValue);
    if (api_data[1] === 200) {
      const token = api_data[0].data.token;
      localStorage.setItem("token", token);
      toast.success("خوش آمدید");
      button.disabled = false;
      button.style.backgroundColor = "#7e42ff";

      window.location.href = call_back !== null ? call_back : "/";
    } else if (api_data[1] === 406) {
      toast.error("کد اشتباه است");
      button.disabled = false;
      button.style.backgroundColor = "#7e42ff";
    } else {
      toast.error("مشکلی پیش آمده");
      button.disabled = false;
      button.style.backgroundColor = "#7e42ff";
    }
  };
  const handleBackToFirst = () => {
    const continer = document.getElementById("continer");
    const continer2 = document.getElementById("continer2");

    continer.style.display = "flex";
    continer2.style.display = "none";
  };
  return (
    <div>
      <ToastContainer rtl={true} position="bottom-right" />
      <div id="continer" className={style.continer}>
        <div className={style.left}>
          <img src={img} />
        </div>
        <div className={style.right}>
          <div className={style.form}>
            <h1>لطفا شماره تماس خود را وارد کنید</h1>
            <h6>شماره تماس </h6>
            <input
              value={numberInputValue}
              onChange={handleNumberChange}
              maxLength={11}
              id="input"
              type="text"
              placeholder="09123456789"
            />
            <button
              id="loginButton"
              style={{
                backgroundColor:
                  numberInputValue.length === 11 ? "#7e42ff" : "#7e42ff48",
                color: "white",
                padding: "10px",
                marginTop: "10px",
              }}
              disabled={numberInputValue.length !== 11}
              onClick={handleLoginButton}
            >
              ورود
            </button>
          </div>
        </div>
      </div>
      <div id="continer2" className={style.continer2}>
        <div className={style.left}>
          <img src={img} />
        </div>
        <div className={style.right}>
          <div className={style.form}>
            <h1>کد را وارد کنید</h1>
            <h6>
              پیامکی حاوی کد ورود به شماره تماس {numberInputValue} ارسال شد
            </h6>
            <Link onClick={handleBackToFirst}>تغییر شماره</Link>
            <h6>کد ورود</h6>
            <input
              value={codeInputValue}
              onChange={handleCodeChange}
              maxLength={4}
              type="text"
              placeholder="1234"
            />
            <button
              id="codeButton"
              style={{
                backgroundColor:
                  codeInputValue.length === 4 ? "#7e42ff" : "#7e42ff48",
                color: "white",
                padding: "10px",
                marginTop: "10px",
              }}
              disabled={codeInputValue.length !== 4}
              onClick={handleCodeButton}
            >
              بررسی
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
