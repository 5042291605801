import style from "./FreeCourseRegister.module.css";
import img from "../../assetes/img/login.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import FreeCourseRegistration_api from "../../api/FreeCourseRegistration";

function FreeCourseRegister() {
  const [numberInputValue, setNumberInputValue] = useState("");

  const handleNumberChange = (event) => {
    setNumberInputValue(event.target.value);
  };

  const handleLoginButton = async () => {
    const api_data = await FreeCourseRegistration_api(numberInputValue);
    if (api_data[1] === 200) {
      toast.success("ثبت نام شد");
      window.location.href = "/courses/66ddbf56716e60ca7ec1eca2";
    } else if (api_data[1] === 401) {
      window.location.href = "/Login?call_back=/free_course";
    }
  };

  return (
    <div>
      <ToastContainer rtl={true} position="bottom-right" />
      <div id="continer" className={style.continer}>
        <div className={style.left}>
          <img src={img} />
        </div>
        <div className={style.right}>
          <div className={style.form}>
            <h3 style={{ fontSize: 20, fontWeight: 700 }}>
              ثبت نام دوره رایگان
            </h3>
            <h6>نام و نام خانوادگی</h6>
            <input
              value={numberInputValue}
              onChange={handleNumberChange}
              maxLength={50}
              id="input"
              type="text"
              placeholder="محسن رضایی"
            />
            <button
              style={{
                backgroundColor:
                  numberInputValue.length >= 4 ? "#7e42ff" : "#7e42ff48",
                color: "white",
                padding: "10px",
                marginTop: "10px",
              }}
              disabled={numberInputValue.length < 4}
              id="button"
              onClick={handleLoginButton}
            >
              ثبت نام
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FreeCourseRegister;
